import { Box, Link, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import "./styles.scss";
import DOMPurify from "dompurify";

const trimDetails = (text: string, limit: number) => {
  if (!text) return { trimmedString: null, showMore: false };
  // Render the show more only if there is a significant amount of text after the limit.
  // This prevents issues where the show more only reveals a few characters.
  if (text.length <= limit || text.length - limit < limit / 2) {
    return {
      trimmedString: text,
      showMore: false,
    };
  }

  const trimmedString = text.substring(0, limit);
  return {
    trimmedString: `${trimmedString}...`,
    showMore: true,
  };
};

export interface TruncatedTextProps {
  text: string;
  limit?: number;
  setAsHTML?: boolean;
}

export const TruncatedText = ({
  text,
  limit,
  setAsHTML,
}: TruncatedTextProps) => {
  const [open, setOpen] = useState<boolean | null>(false);
  const sanitizedText = DOMPurify.sanitize(text);
  const { trimmedString, showMore } = trimDetails(sanitizedText, limit || 300);
  const textToRender = open ? sanitizedText : trimmedString;
  return (
    <>
      {!!textToRender &&
        (setAsHTML ? (
          <Typography
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: textToRender,
            }}
          />
        ) : (
          <Typography>{textToRender}</Typography>
        ))}

      {showMore && (
        <Box className="vr-truncated-text">
          <Link
            className="read-more"
            component="button"
            variant="h6"
            underline="hover"
            style={{ color: "#013D58" }}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? "Read less" : "Read more"}
          </Link>
        </Box>
      )}
    </>
  );
};
